import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Input, MultiOption, Select } from "@ster/ster-toolkit";
import { Alert, Checkbox, Typography } from "antd";
import { memo } from "react";
import { useSelector } from "react-redux";

import { PackageChoiceEnum, PackageType } from "../../../api";
import { StoreModel } from "../../../store/models";
import { getPopupContainerSubOrders } from "../../../utils";
import { isSameOrAfterDay, isSameOrBeforeDay } from "../../../utils/dateHelper";
import { formItemLayout } from "../../partials/Form";
import { OnlineSubOrderFormProps } from "./models";
import styles from "./OnlineSubOrder.module.less";
import PackageSelectOnline from "./partials/PackageSelectOnline";
import PeriodSelectOnline from "./partials/PeriodSelectOnline";
import ProgramSiteOrContextTargeting from "./partials/ProgramSiteOrContextTargeting";

/**
 * Toevoegen/wijzigen van een online deelorder.
 */
const OnlineSubOrderForm = memo(
  ({
    subOrder,
    period,
    packageChoice,
    handleFinishFailed,
    handleValuesChange,
    form,
    contextTargets,
    handleSelectAll,
    packages,
    programs,
    sites,
    selectedPackage,
  }: OnlineSubOrderFormProps) => {
    const { i18n } = useLingui();

    const settings = useSelector((store: StoreModel) => store.settings);

    const showMarketIndexWarning =
      settings.settings?.show_digital_marketindex_warning &&
      packageChoice === PackageChoiceEnum.Video &&
      subOrder.period?.from &&
      subOrder.period?.to &&
      // Alleen voor oktober 2024 (maand is zero-based, dus maand 9)
      isSameOrAfterDay(new Date(2024, 9, 31), subOrder.period.from) &&
      isSameOrBeforeDay(new Date(2024, 9, 1), subOrder.period.to);

    return (
      <Form
        {...formItemLayout}
        onValuesChange={handleValuesChange}
        onFinishFailed={handleFinishFailed}
        form={form}
        name={`suborder-${subOrder.id}`}
        wrapperCol={{ span: 12 }}
      >
        {showMarketIndexWarning && (
          <Alert
            showIcon
            message=""
            type="warning"
            style={{ maxWidth: "800px", marginBottom: 24 }}
            description={
              <Trans>
                <b>Let op:</b> voor de maand oktober geldt een marktindex van
                110. Indien je een maandoverschrijdende campagne inkoopt die de
                maand oktober bevat, dient de order opgesplitst te worden per
                maand. Indien je hier vragen over hebt, neem dan contact op met{" "}
                <a href="mailto:digital@ster.nl">digital@ster.nl</a>.
              </Trans>
            }
          />
        )}
        <Form.Item
          label={i18n._(t`Campagnenaam`)}
          name="campaignName"
          rules={[
            { required: true, message: i18n._(t`Voer een campagnenaam in`) },
          ]}
        >
          <Input type="text" maxLength={30} />
        </Form.Item>

        <Form.Item label={i18n._(t`Periode`)} shouldUpdate>
          {({ getFieldValue }) => {
            const useTime = getFieldValue("useTime");
            return (
              <>
                <Form.Item
                  name="period"
                  rules={[
                    { required: true, message: i18n._(t`Kies een periode`) },
                  ]}
                  noStyle
                >
                  <PeriodSelectOnline period={period} useTime={useTime} />
                </Form.Item>
                {useTime && (
                  <>
                    <br />
                    <Typography.Text>
                      {i18n._(
                        t`Vergeet niet een start- en eindtijd op te geven.`
                      )}
                    </Typography.Text>
                  </>
                )}
              </>
            );
          }}
        </Form.Item>

        <Form.Item
          label={i18n._(t`Start-/eindtijd`)}
          name="useTime"
          valuePropName="checked"
          helpText={i18n._(
            t`Zodra dit aan staat, kun je in bovenstaande periode veld een start- en eindtijd opgeven voor de gehele periode.`
          )}
        >
          <Checkbox />
        </Form.Item>

        <PackageSelectOnline
          packages={packages}
          period={subOrder.period}
          enabled={subOrder.period !== undefined}
          numberOfExcludedDays={subOrder.excluded?.length}
          spreadEnabled={subOrder.spread ?? false}
          value={subOrder._package?.code}
          hideIndices
        />

        {packageChoice === PackageChoiceEnum.Video && (
          <Form.Item
            label={i18n._(t`Spotlengte`)}
            name="videoLength"
            rules={[
              {
                required: true,
                message: i18n._(t`Kies een spotlengte`),
              },
            ]}
          >
            <Select
              placeholder={i18n._(t`Kies een spotlengte`)}
              options={selectedPackage?.internetFormat?.map((s) => ({
                label: `${s.spotLength}"`,
                value: s.spotLength ?? "",
              }))}
              disabled={!selectedPackage}
              getPopupContainer={getPopupContainerSubOrders}
            />
          </Form.Item>
        )}

        {packageChoice === PackageChoiceEnum.Display &&
          (selectedPackage?.internetFormat?.length ?? 0) > 1 && (
            <Form.Item
              label={i18n._(t`Formaat`)}
              name="format"
              rules={[
                {
                  required: true,
                  message: i18n._(t`Kies een formaat`),
                },
              ]}
            >
              <Select
                placeholder={i18n._(t`Kies een formaat`)}
                options={selectedPackage?.internetFormat?.map((s) => ({
                  label: `${s.description}`,
                  value: s.id ?? "",
                }))}
                disabled={!selectedPackage}
                getPopupContainer={getPopupContainerSubOrders}
              />
            </Form.Item>
          )}

        {selectedPackage?.type === PackageType.HouseDisplay && (
          <>
            <div className={styles.houseDisplayAlert}>
              <Alert
                showIcon
                type="warning"
                message=""
                description={
                  <Trans>
                    Voor de inzet van gratis banners op het NPO Netwerk: Graag
                    eerst zelf afstemmen met de andere omroepen of het ok is om
                    de banner hier uit te serveren. Je eigen domein is
                    natuurlijk geen probleem.
                  </Trans>
                }
              />
            </div>

            <Form.Item
              label={i18n._(t`Sites`)}
              name="site"
              rules={[
                {
                  required: true,
                  message: i18n._(t`Kies 1 of meer sites`),
                },
              ]}
            >
              <Select.Multi
                placeholder={i18n._(t`Kies 1 of meer sites`)}
                showSearch
                singleLine={false}
                className={styles["selection-max-width"]}
                getPopupContainer={getPopupContainerSubOrders}
                onSelectAll={handleSelectAll}
                selectAllLabel={i18n._(t`Alles selecteren`)}
                deselectAllLabel={i18n._(t`Niets selecteren`)}
              >
                {sites.map((s) => (
                  <MultiOption
                    key={s.name}
                    value={s.name ?? ""}
                    title={s.name}
                  />
                ))}
              </Select.Multi>
            </Form.Item>
          </>
        )}

        {selectedPackage?.type === PackageType.RunOnSite && (
          <ProgramSiteOrContextTargeting
            programs={programs}
            contextTargets={contextTargets}
            handleSelectAllSites={handleSelectAll}
            sites={sites}
            isDisplay={packageChoice === PackageChoiceEnum.Display}
          />
        )}
      </Form>
    );
  }
);

export default OnlineSubOrderForm;
