import { Trans } from "@lingui/macro";
import { Alert } from "antd";
import { memo, useMemo } from "react";

import { MediumEnum, PackageType } from "../../api";
import { isSameOrAfterDay, isSameOrBeforeDay } from "../../utils/dateHelper";
import { Top2000WarningProps } from "./models";
import styles from "./Top2000Warning.module.less";

const Top2000Warning = memo(
  ({
    medium,
    from,
    to,
    _package,
    channels,
    className,
    targetGroupId,
  }: Top2000WarningProps) => {
    const isTop2000Package = useMemo(
      () => _package?.type === PackageType.Top2000,
      [_package]
    );

    const showGenericWarning = useMemo(() => {
      const currentYear = from.getFullYear();
      return (
        !isTop2000Package &&
        medium === MediumEnum.Radio &&
        (!channels || channels.includes("R2")) &&
        isSameOrAfterDay(to, new Date(`${currentYear}-12-25`)) &&
        isSameOrBeforeDay(from, new Date(`${currentYear}-12-31`))
      );
    }, [channels, from, isTop2000Package, medium, to]);

    // Melding voor het combi pakket met de doelgroep 25-67 en voor het algemeen nut pakket
    const showRadio2Warning = useMemo(
      () =>
        showGenericWarning &&
        ((_package?.isCombi && targetGroupId) ||
          _package?.type === PackageType.AlgemeenNut),
      [_package?.isCombi, _package?.type, showGenericWarning, targetGroupId]
    );

    // Melding voor het combi pakket met de doelgroep 25-54 op Radio 2 en 3FM vanaf 18 december
    const showRadio2And3Warning = useMemo(() => {
      const currentYear = from.getFullYear();
      return (
        !isTop2000Package &&
        medium === MediumEnum.Radio &&
        channels?.length === 2 &&
        channels?.includes("R2") &&
        channels?.includes("R3") &&
        isSameOrAfterDay(to, new Date(`${currentYear}-12-18`)) &&
        isSameOrBeforeDay(from, new Date(`${currentYear}-12-31`))
      );
    }, [channels, from, isTop2000Package, medium, to]);

    if (showRadio2And3Warning) {
      return (
        <Alert
          showIcon
          className={`${styles.top2000} ${className}`}
          type="error"
          description={
            <Trans>
              Let op: Van 18 tot en met 31 december is deze inkoopoptie niet
              beschikbaar in verband met Serious Request en de Top 2000. Neem
              contact op met je accountmanager of planningsteam voor de
              mogelijkheden.
            </Trans>
          }
        />
      );
    }

    if (showRadio2Warning) {
      return (
        <Alert
          showIcon
          className={`${styles.top2000} ${className}`}
          type="warning"
          description={
            <Trans>
              Let op: dit pakket geldt normaliter voor 4 radiozenders, waaronder
              NPO Radio 2. Van 25 t/m 31 december wordt op die zender echter de
              Top 2000 uitgezonden, waarvoor andere inkoopopties gelden. Je
              campagne zal dan ook niet uitgeleverd worden op NPO Radio 2.
              Geïnteresseerd in de inkoopopties rondom de Top 2000? Neem contact
              op met je accountmanager of bekijk onze{" "}
              <a
                href="https://www.ster.nl/top2000"
                rel="noopener noreferrer"
                target="_blank"
              >
                website
              </a>
              .
            </Trans>
          }
        />
      );
    }

    if (showGenericWarning) {
      return (
        <Alert
          showIcon
          className={`${styles.top2000} ${className}`}
          type="warning"
          description={
            <Trans>
              Let op: de Top 2000 op NPO Radio 2 vindt plaats van 25 t/m 31
              december. In deze periode zijn er geen reguliere pakketten, maar
              is het wel mogelijk Top2000 Ster Time (met of zonder spreiding) of
              Your Time in te kopen.
              <br />
              Meer informatie over deze inkoopopties vind je op onze{" "}
              <a
                href="https://www.ster.nl/top2000"
                rel="noopener noreferrer"
                target="_blank"
              >
                website
              </a>
              .
            </Trans>
          }
        />
      );
    }

    return null;
  }
);

export default Top2000Warning;
